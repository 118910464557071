import React from "react";
import { useTranslation } from "react-i18next";
import Card from "clutch/src/Card/Card.jsx";

import { FALLBACK_IMAGE_URL } from "@/app/constants.mjs";
import BlitzLogoLoading from "@/shared/BlitzLogoLoading.jsx";
import DataTable from "@/shared/DataTable.jsx";
import { SharedMatchLayout } from "@/shared/Match.jsx";
import { MainColumnsContainer } from "@/shared/Match.style.jsx";

export default function WaitingForMatch({ profileId }: { profileId: string }) {
  const { t } = useTranslation();
  const cols = [
    {
      display: (
        <div style={{ color: "var(--turq)" }}>
          {t("fortnite:match.mySquad", "My squad")}
        </div>
      ),
      align: "left",
    },
    { display: t("common:elims", "Elims"), align: "center", isStat: true },
    { display: t("common:knocks", "Knocks"), align: "center", isStat: true },
  ];
  const rows = [new Array(cols.length).fill({ display: "-", value: "-" })];
  return (
    <SharedMatchLayout
      match
      title={t("fortnite:match.gameStartWait", "Waiting for game to start")}
      image={FALLBACK_IMAGE_URL}
      imageLink={`/fortnite/profile/${profileId}`}
    >
      <MainColumnsContainer>
        <div className="span-1">
          <DataTable
            indexCol
            indexColTitle={"#"}
            cols={cols}
            rows={rows}
            sortDir="DESC"
            sortCol={1}
            sortColTiebreak={2}
            sortable={false}
          />
        </div>
        <div className="span-2">
          <div className="relative">
            <Card loading style={{ height: null }} />
            <div className="absolute-center">
              <BlitzLogoLoading />
            </div>
          </div>
        </div>
      </MainColumnsContainer>
    </SharedMatchLayout>
  );
}
