import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import Feed from "@/game-fortnite/components/Feed.jsx";
import MatchDataTableInGame from "@/game-fortnite/components/MatchDataTableInGame.jsx";
import MatchWaiting from "@/game-fortnite/components/MatchWaiting.jsx";
import { FORTNITE_PLAYLISTS } from "@/game-fortnite/constants/playlists.mjs";
import {
  FORTNITE_QUEUES,
  FORTNITE_QUEUES_BE,
} from "@/game-fortnite/constants/queues.mjs";
import type { MatchCore } from "@/game-fortnite/models/game-events.mjs";
import Static from "@/game-fortnite/utils/static.mjs";
import { useStatsBreakdownInGame } from "@/game-fortnite/utils/use-stats-breakdown.mjs";
import Mouse from "@/inline-assets/mouse.svg";
import BlitzLogoLoading from "@/shared/BlitzLogoLoading.jsx";
import { SharedMatchLayout } from "@/shared/Match.jsx";
import matchRefs from "@/shared/Match.refs.jsx";
import { MainColumnsContainer } from "@/shared/Match.style.jsx";
import SharedStatsBreakdown from "@/shared/StatsBreakdown.jsx";
import { Timer } from "@/shared/Time.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function InGame(): React.JSX.Element {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const liveGame: MatchCore | null = state.fortnite.liveGame;
  const profileId: string | undefined =
    readState.settings.lastLoggedInIdByGame.fortnite ??
    liveGame?.localPlayer?.accountId;
  const statsBreakdownInGame = useStatsBreakdownInGame(liveGame);
  if (!liveGame) return <MatchWaiting profileId={profileId} />;
  const gameMode = liveGame.mode ?? liveGame.gameMode; // MatchInit ?? MatchStart
  const mode = Object.getOwnPropertySymbols(FORTNITE_PLAYLISTS).find((s) =>
    FORTNITE_PLAYLISTS[s]?.internal?.includes(gameMode),
  );
  const queue = Object.getOwnPropertySymbols(FORTNITE_QUEUES).find((s) =>
    FORTNITE_QUEUES[s]?.internal?.includes(gameMode),
  );

  return (
    <SharedMatchLayout
      match={liveGame}
      title={t("fortnite:match.gameInProgress", "Game in progress")}
      image={Static.getProfileImage(liveGame.gameId)}
      borderColor="var(--shade3)"
      underTitle={
        <div className="flex align-center gap-2 color-shade2">
          <Mouse style={{ width: "var(--sp-6)", height: "var(--sp-6)" }} />
          {mode && (
            <>
              <div>{t(...FORTNITE_PLAYLISTS[mode].t)}</div>·
            </>
          )}
          <div style={{ textTransform: "capitalize" }}>
            {queue
              ? t(...FORTNITE_QUEUES[queue].t)
              : (
                  FORTNITE_QUEUES_BE.find((i) =>
                    new RegExp(i, "i").test(gameMode),
                  ) ?? FORTNITE_QUEUES_BE[0]
                ).toLowerCase()}
          </div>
          ·
          <div>
            <Timer
              startTime={liveGame.startTime ?? liveGame.startedAt}
              format={undefined}
            />
          </div>
        </div>
      }
    >
      <MainColumnsContainer className={matchRefs.columnsContainerClassName?.()}>
        <div
          className="span-1 flex direction-column"
          style={{ display: "flex" }} // This overrides MainColumnsContainer's display grid
        >
          <Feed profileId={profileId} match={liveGame} />
        </div>
        <div className="span-2">
          {statsBreakdownInGame.rowData.length ? (
            React.createElement(
              SharedStatsBreakdown,
              // This JSX component will give a TS error because the inferred {any} types will not match
              // my TypeScript typings, the Component will have to be refactored to TSX at a later time
              // @ts-ignore
              statsBreakdownInGame,
            )
          ) : (
            <div className="relative">
              <Card loading style={{ height: "var(--sp-48)" }} />
              <div className="absolute-center">
                <BlitzLogoLoading />
              </div>
            </div>
          )}
          {matchRefs.InContentBanner && <matchRefs.InContentBanner />}
          <MatchDataTableInGame match={liveGame} />
        </div>
      </MainColumnsContainer>
    </SharedMatchLayout>
  );
}
// This is a app route not a web route, no title + description necessary
export function meta() {
  return {
    title: ["", ""],
    description: ["", ""],
  };
}
